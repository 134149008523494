<template>
  <div class="mt-2">
    <Header></Header>
    <Tabla/>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'verDesacatos',
  components: {
    Tabla: defineAsyncComponent(
      () => import('./componentsDesacatos/Tabla.vue')
    ),
    Header: defineAsyncComponent(
      () => import('./componentsDesacatos/Header.vue')
    )
  }
}
</script>
<style scoped>

</style>
